import React, {useEffect, useState,useContext} from "react";
import sanityClient from "../../client.js"
import './frontpage.styles.scss'
import Zoomable from "react-instagram-zoom";
// import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";
import ZoomSlider from 'react-instagram-zoom-slider'
import TitleCursor from '../../components/titleCursor/titleCursor.component'
// import ZoomImage from '../../components/zoom-image/ZoomImage.jsx'
import {PortableText} from '@portabletext/react'
import imageUrlBuilder from '@sanity/image-url'
// import Masonry from 'react-masonry-css'
import Masonry from 'react-smart-masonry';
import {MouseContext} from "../../context/mouse-context.js";
import { isMobile } from 'detect-touch-device';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

const builder = imageUrlBuilder(sanityClient)

function urlFor(source) {
  return builder.image(source)
}

const Frontpage = ({faveFilter, isNarrow, isTablet})=>{
    // console.log(isNarrow)
    const [pageContent, setPageContent] = useState(null)
    const {cursorChangeHandler} = useContext(MouseContext);
    const [currentPageContent, setCurrentPageContent] = useState(null)
    const [pageWidth, setPageWidth] = useState(window.innerWidth)

    // useEffect(()=>{
    //     if (!pageContent) return
    //     // updateProjectRows(window.innerWidth)
    //     setPageWidth(window.innerWidth)
    //     const handler = () => {
    //         if (window.innerWidth != pageWidth){
    //             window.location.reload(false)
    //         }
    //     };
    //     window.addEventListener('resize', handler); // Or window resize
    //     return () => {
    //         // On unmount, remove the handler
    //         window.removeEventListener("resize", handler); // Or window resize
    //     };
    // }, [pageContent]);

    useEffect(()=>{
        // console.log(pageContent)
        if (pageContent == null) return
        if (faveFilter == undefined || faveFilter == false){
            setCurrentPageContent(pageContent)
        }else{
            const currentPageContent = pageContent.filter(item =>{
                return item.favourite == true
            })
            // console.log(currentPageContent)
            setCurrentPageContent(currentPageContent)
        }
        setPageWidth(window.innerWidth)
        const handler = () => {
            if (window.innerWidth != pageWidth){
                // console.log('resize')
                // window.location.reload(false)
            }
        };
        window.addEventListener('resize', handler); // Or window resize
        return () => {
            // On unmount, remove the handler
            window.removeEventListener("resize", handler); // Or window resize
        };
    },[pageContent, faveFilter])

    const breakpointColumnsObj = {
        default: 6,
        1300: 4,
        850: 2
      };

    const breakpoints = { mobile: 0, tablet: 850, desktop: 1300 };

    //   const isNarrow = useMediaQuery({
    //     query: '(max-width: 850px)'
    //   })

    useEffect(() =>{ 
        let fetchContentQuery = `*[_type == "project"]{...} | order(orderRank)`
        sanityClient
        .fetch(fetchContentQuery)
        .then(data => {
            // console.log(data);
            setPageContent(data)})
        .catch(console.error)
    },[]);

    const projectBox =(project)=>{
        
        // if (isNarrow){ // or ipad landscape
        //     let doublePixelWidth = Math.round(window.innerWidth / 2) * 2
        //     return <div
        //     key={project._id}
        //     className="project-box"
        //     >
        //     <a href={`/HelmutSmits/${project.slug.current}`}>

        //         {isTablet ?
        //             <img src={urlFor(project.highlightImage).width(doublePixelWidth).url()}/>
        //             :
        //             <Zoomable>
        //             <img src={urlFor(project.highlightImage).width(doublePixelWidth).url()}/>
        //             </Zoomable>
        //         }
        //     </a>
        //     </div>
        // }else
         if (isMobile){
            let doublePixelWidth = Math.round(window.innerWidth)
            if (window.innerWidth > 1100){
                doublePixelWidth = Math.round(window.innerWidth / 2)
            }

            return <a href={`/${project.slug.current}`}> 
            <div
                key={project._id}
                className="project-box"
                >
                {/* { isTablet ?
                    <img src={urlFor(project.highlightImage).width(doublePixelWidth).url()}/>
                    : */}
                    {/* <Zoomable

                        onTouchStart={(e)=>{console.log(e);console.log(window.scrollY);window.scroll( 0, window.scrollY )}}
                    > */}
                    <img src={urlFor(project.highlightImage).width(doublePixelWidth).url()}/>
                    {/* </Zoomable> */}
                {/* } */}
                    
                </div>
                </a>
        }else{
            let doublePixelWidth = Math.round(window.innerWidth)
            if (window.innerWidth > 1100){
                doublePixelWidth = Math.round(window.innerWidth / 2)
            }

            // const slides = [<img src={process.env.PUBLIC_URL + '/assets/0.png'} alt="First slide" />, <img src={process.env.PUBLIC_URL + '/assets/0.png'} alt="Second slide" />]

            return <a href={`/${project.slug.current}`}> 
            <div
                key={project._id}
                className="project-box"
                onMouseEnter={() => cursorChangeHandler(project.title)}
                onMouseLeave={() => cursorChangeHandler("")}
                >
                    {/* <ZoomSlider slides={slides} /> */}
                    {/* <ZoomImage IMG_URL={urlFor(project.highlightImage).width(doublePixelWidth).url()}/> */}
                    <img src={urlFor(project.highlightImage).width(doublePixelWidth).url()}/>
                </div>
                </a>
        }
    }
// console.log(currentPageContent)
    if(!currentPageContent) return <></>
    return(
        <div className="frontpage">
            <TitleCursor/>
           <div className="content-wrapper">

            {/* <Masonry
            breakpointCols={breakpointColumnsObj}
            className="masonry-grid"
            columnClassName="masonry-grid_column"
            >
                {currentPageContent.map((project)=>{
                   return projectBox(project)
               })
            }
            </Masonry> */}

            <Masonry
                key={pageWidth}
                breakpoints={breakpoints}
                columns={{ mobile: 2, tablet: 4, desktop: 6 }}
                gap={{ mobile: 22, tablet: 44, desktop: 44 }}
                autoArrange={true}
                >
                {currentPageContent.map((project)=>{
                   return projectBox(project)
                })
            }
            </Masonry> 

           </div>
           <div className="bottom-wrapper">
                <div className="bottom-project-count">
                    {currentPageContent.length} Projects
                </div>
            </div>
            {/* <div className="no-touch"></div> */}
        </div>
    )
}

export default Frontpage