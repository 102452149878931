import './App.scss';
import React, {useState, useEffect, useRef } from "react";
import { useIdleTimer } from 'react-idle-timer'
import sanityClient from "./client"

import {
  Link,
  Routes,
  Route,
  useLocation
} from "react-router-dom";

import Frontpage from './pages/frontpage/frontpage.page';
import Project from './pages/project/project.page';
import Info from './pages/info/info.page';
import Menu from './components/menu/menu.component'
// import { useDvdScreensaver } from 'react-dvd-screensaver'
import { useMediaQuery } from 'react-responsive';
import Screensaver from './components/Screensaver/Screensaver.component';

function App() {

  const [settings, setSettings] = useState(null);
  const [idleTimeout, setIdleTimeout] = useState(10000)
  const [faveFilter, toggleFaveFilter] = useState(false)
  const [preventIdle, setPreventIdle] = useState(false)
  const [showScreensaver, setShowscreensaver] = useState(false)
  const [globalFontsize, setGlobalFontsize] = useState('100%')
  const appRef = useRef()
  const isNarrow = useMediaQuery({
    query: '(max-width: 767px)'
  })
  const isTablet = useMediaQuery({ minWidth: 767, maxWidth: 1100 })

  useEffect(() =>{ 

    //Set font size
    // let size = window.getComputedStyle(appRef.current, null).getPropertyValue('font-size');
    // console.log(size);
    // let fontSizePercentage = parseFloat(size) / 16 * 100;
    // console.log(fontSizePercentage);
    // setGlobalFontsize(fontSizePercentage+'%')

    let fetchContentQuery = `*[_id == 'settings']{...}[0]`
    sanityClient
    .fetch(fetchContentQuery)
    .then(data => {setSettings(data);setIdleTimeout(data.emojiSeconds * 1000)})
    .catch(console.error);
},[]);

  const onIdle = () => {
    setShowscreensaver(true)
  }

  const onActive = (event) => {
    setShowscreensaver(false)
  }

  const idleTimer = useIdleTimer({ onIdle, onActive, timeout: idleTimeout, events: [
    'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange',
      'focus'
  ], })

  useEffect(()=>{
    if(preventIdle){
      // console.log('preventing idle')
      idleTimer.pause()
    }else{
      // console.log('idle reactivated')
      idleTimer.activate()
    }
  },[preventIdle])

  useEffect(()=>{
    // console.log(appRef)
    // if (appRef == null || appRef == undefined)return
    if (appRef.current == undefined) return

    let size = window.getComputedStyle(appRef.current, null).getPropertyValue('font-size');
    // console.log(size);
    let fontSizePercentage = parseFloat(size) / 16 * 100;
    // console.log(fontSizePercentage);
    //makes the font size way too big?
    setGlobalFontsize(fontSizePercentage+'%')

  })

  // const [showScreensaver, setShowscreensaver] = useState(false)
  // const [emojiCounter, setEmojiCounter] = useState(0)

  // const updateEmojiNumber = ()=>{
  //   console.log(emojiCounter)
  //   if (emojiCounter == 23){
  //     setEmojiCounter(0)
  //   } else{
  //     setEmojiCounter(emojiCounter + 1)
  //   }
  // }

  // const { containerRef, elementRef, hovered, impactCount } = useDvdScreensaver({
  //   freezeOnHover: true,
  //   speed: isNarrow ? 1 : isTablet ? 2 : 2
  // });

  // const emojiRef = useRef()
  // const [emojiHeight, setEmojiHeight] = useState('0px');

  // let emojiArray = ["😂","😝","😁","😱","👉","🙌","🍻","🔥","🌈","🎈","🌹","💄","🎀","⚽","🎾","🏁","😡","👿","🐻","🐶","🐬","🐟","🍀","👀","🚗","🍎","💝","💙","👌","😍","😉","😓","😳","💪","💩","🍸","🔑","💖","🌟","🎉","🌺","🎶","👠","🏈","⚾","🏆","👽","💀","🐵","🐮","🐩","🐎","💣","👃","👂","🍓","💘","💜","👊","💋","😘","😜","😵","🙏","👋","🚽","💃","💎","🚀","🌙","🎁","⛄","🌊","⛵","🏀","🎱","💰","👶","👸","🐰","🐷","🐍","🐫","🔫","👄","🚲","🍉","💛","💚"]

  // let emojiRatio = [1.3, 1, 0.95, 0.92, 1, 1, 0.82, 1, 0.96]

  // useEffect(()=>{

  //   if (emojiCounter == 23){
  //     setEmojiCounter(0)
  //   } else{
  //     setEmojiCounter(emojiCounter + 1)
  //   }

    // function getNewRand(max) {
    //   let newRand = Math.floor(Math.random() * max);
    //   if (newRand >= emojiCounter) {
    //     newRand += 1;
    //   }
    //   return newRand;
    // }

    // let newCount = getNewRand(8)
    // setEmojiCounter(newCount)
    
    // setEmojiStyle
    // elementRef.current

    // if (emojiRef.current != undefined){
    //   // if ('height' in emojiRef.current){
    //     setEmojiHeight(emojiRef.current.height)
    //     console.log(emojiRef.current.height)
    //   // }
    // }
    // console.log(emojiRef.current)

    //kanskje lettest å bare forhåndsprogrammere høydene på hver emoji i stedet for å hente dem fra koden
        // bruk høyde/bredde-ratio

  // },[impactCount])

// const returnEmojiHeight = () =>{

//   if (isNarrow){
//     // console.log(100 / emojiRatio[emojiCounter]);
//     return 100 / emojiRatio[emojiCounter] + 'px'
//   }else{
//     return (window.innerWidth * 0.08) / emojiRatio[emojiCounter] + 'px'
//   }

// }

  // const ScreensaverEmoji = ()=>{
  //   // console.log('/assets/emoji/' + emojiCounter + '.png')
  //   // return <>{emojiArray[emojiCounter]}</>
  //   return(
  //     <img src={process.env.PUBLIC_URL + '/assets/' + emojiCounter + '.png'}/>

  //     // <img src={} />
  //   )
  // }


if(!settings) return <></>
  return (
    <div
    ref={appRef}
    // style={{fontSize:globalFontsize}}
    >
      <div className='hidden-emojis'>
            <img src={process.env.PUBLIC_URL + '/assets/0.png'}/>
            <img src={process.env.PUBLIC_URL + '/assets/1.png'}/>
            <img src={process.env.PUBLIC_URL + '/assets/2.png'}/>
            <img src={process.env.PUBLIC_URL + '/assets/3.png'}/>
            <img src={process.env.PUBLIC_URL + '/assets/4.png'}/>
            <img src={process.env.PUBLIC_URL + '/assets/5.png'}/>
            <img src={process.env.PUBLIC_URL + '/assets/6.png'}/>
            <img src={process.env.PUBLIC_URL + '/assets/7.png'}/>
            <img src={process.env.PUBLIC_URL + '/assets/8.png'}/>
            <img src={process.env.PUBLIC_URL + '/assets/9.png'}/>
            <img  src={process.env.PUBLIC_URL + '/assets/10.png'}/>
            <img  src={process.env.PUBLIC_URL + '/assets/11.png'}/>
            <img  src={process.env.PUBLIC_URL + '/assets/12.png'}/>
            <img  src={process.env.PUBLIC_URL + '/assets/13.png'}/>
            <img  src={process.env.PUBLIC_URL + '/assets/14.png'}/>
            <img  src={process.env.PUBLIC_URL + '/assets/15.png'}/>
            <img  src={process.env.PUBLIC_URL + '/assets/16.png'}/>
            <img  src={process.env.PUBLIC_URL + '/assets/17.png'}/>
            <img  src={process.env.PUBLIC_URL + '/assets/18.png'}/>
            <img  src={process.env.PUBLIC_URL + '/assets/19.png'}/>
            <img  src={process.env.PUBLIC_URL + '/assets/20.png'}/>
            <img  src={process.env.PUBLIC_URL + '/assets/21.png'}/>
            <img  src={process.env.PUBLIC_URL + '/assets/22.png'}/>
            <img  src={process.env.PUBLIC_URL + '/assets/23.png'}/>
            <img  src={process.env.PUBLIC_URL + '/assets/24.png'}/>
      </div>
      {showScreensaver && settings.showEmoji == true ?
        <Screensaver isNarrow={isNarrow} isTablet={isTablet}/>
      :''
      }
      <Menu faveFilter={faveFilter} toggleFaveFilter={toggleFaveFilter}/>
      <Routes>
        <Route path="/" element={<Frontpage faveFilter={faveFilter} isNarrow={isNarrow} isTablet={isTablet} />} />
        <Route path="/Info" element={<Info />} />
        <Route path="/:slug" element={<Project setPreventIdle={setPreventIdle} />} />
      </Routes>
    </div>
  );
}

export default App;
