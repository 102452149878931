import React, {useEffect, useState} from "react";
import './menu.styles.scss'
import {AiOutlineMenu } from 'react-icons/ai';
import {FiInstagram } from 'react-icons/fi';


import {
    useLocation,
    useNavigate
} from "react-router-dom";

const Menu = ({faveFilter, toggleFaveFilter})=>{

    const [mobileRightMenu, toggleMobileRightMenu] = useState(false)
    
    const location = useLocation()
    const navigate = useNavigate()

    return(
        <div className="menu-wrapper">
            <div className="menu-left">
                <span className="menu-title" onClick={()=>navigate("/")}>Helmut Smits</span>
                <span className={location.pathname != '/Info' ? 'underlined' : ''} onClick={()=>navigate("/")}>Work</span>
                <span className={location.pathname == '/Info' ? 'underlined' : ''} onClick={()=>navigate("/Info")}>Info</span>
                <a className="instagram-icon" href="https://www.instagram.com/helmutsmits" target="_blank"><FiInstagram/></a>
            </div>
            
            {location.pathname == '/' ?
            <>
            <div className="menu-right-mobile-icon" onClick={()=>toggleMobileRightMenu(!mobileRightMenu)}>
                <AiOutlineMenu/>
            </div>
            <div className={`menu-right-mobile ${mobileRightMenu ? 'menu-right-mobile-display' : ''}`}>
                <span className='filter-option-wrapper' onClick={()=>{window.scrollTo({top: 0,behavior: 'smooth'});toggleMobileRightMenu(!mobileRightMenu);toggleFaveFilter(!faveFilter)}}>
                    <span className={`filter-checkbox ${!faveFilter ? 'checked' : 'unchecked'}`}></span> Show all projects
                </span>
                <span className='filter-option-wrapper' onClick={()=>{window.scrollTo({top: 0,behavior: 'smooth'});toggleMobileRightMenu(!mobileRightMenu);toggleFaveFilter(!faveFilter)}}>
                    <span className={`filter-checkbox ${faveFilter ? 'checked' : 'unchecked'}`}></span> Show Helmut's favourites
                </span>
            </div>
            <div className="menu-right">
                <span className='filter-option-wrapper' onClick={()=>{window.scrollTo({top: 0,behavior: 'smooth'});toggleFaveFilter(!faveFilter)}}>
                    <span className={`filter-checkbox ${!faveFilter ? 'checked' : 'unchecked'}`}></span> Show all projects
                </span>
                <span className='filter-option-wrapper' onClick={()=>{window.scrollTo({top: 0,behavior: 'smooth'});toggleFaveFilter(!faveFilter)}}>
                    <span className={`filter-checkbox ${faveFilter ? 'checked' : 'unchecked'}`}></span> Show Helmut's favourites
                </span>
            </div>
            </>
            :''
            }
        </div>
    )
}

export default Menu