import React, {useEffect, useState,useContext,useRef} from "react";
import sanityClient from "../../client.js"
import './info.styles.scss'
// import TitleCursor from '../../components/titleCursor/titleCursor.component'
import Menu from '../../components/menu/menu.component'
import Bottom from '../../components/bottom/bottom.component'
import {PortableText} from '@portabletext/react'
import imageUrlBuilder from '@sanity/image-url'
import { useMediaQuery } from 'react-responsive'

import { BiChevronUp } from "react-icons/bi";
import { BiChevronDown } from "react-icons/bi";

const builder = imageUrlBuilder(sanityClient)

function urlFor(source) {
  return builder.image(source)
}

const Info = ()=>{

    const [pageContent, setPageContent] = useState(null)
    const [activeSection, setActiveSection] = useState('exhibitions')
    const [navOpen, toggleNavOpen] = useState(false)
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 850px)' })

    const exhibitionsRef = useRef(null)
    const commissionsRef = useRef(null)
    const grantsawardsRef = useRef(null)
    const publicationsRef = useRef(null)
    const lecturesRef = useRef(null)

    const isNarrow = useMediaQuery({
        query: '(max-width: 850px)'
      })

      useEffect(() => {
        
        window.addEventListener('scroll', scrollHandler);
     
        return () => window.removeEventListener('scroll', scrollHandler);
        
    }, []);
    
    const scrollHandler = () => {

        toggleNavOpen(false)
        
        if(window.pageYOffset + (window.innerHeight /2) < commissionsRef.current.offsetTop){
            setActiveSection('exhibitions')
        }
        if(window.pageYOffset + (window.innerHeight /2) >= commissionsRef.current.offsetTop){
            setActiveSection('commissions')
        }
        if(window.pageYOffset + (window.innerHeight /2) >= lecturesRef.current.offsetTop && window.pageYOffset + (window.innerHeight /2) < grantsawardsRef.current.offsetTop){
            setActiveSection('lectures')
        }
        if(window.pageYOffset + (window.innerHeight /2) >= grantsawardsRef.current.offsetTop && window.pageYOffset + (window.innerHeight /2) < publicationsRef.current.offsetTop){
            setActiveSection('grantsawards')
        }
        if(window.pageYOffset + (window.innerHeight /2) >= publicationsRef.current.offsetTop){
            setActiveSection('publications')
        }
    }
    
    const scrollToHandler = (ref) =>{
        window.scrollTo(0,ref.current.offsetTop - 60)
    }

    useEffect(() =>{ 
        let fetchContentQuery = `*[_id == "info"]{...}[0]`
        sanityClient
        .fetch(fetchContentQuery)
        .then(data => {setPageContent(data)})
        .catch(console.error)
    },[]);

    const components = {
        types: {
          image: ({value}) =>{
            // console.log(value)
            let width = 100;
            if (!isNarrow){ // add narrow
                if('imageWidth' in value){
                    width = value.imageWidth
                }
            }
          return(<div className={` width-${width}`}>
                <img src={urlFor(value.asset).width(Math.round(window.innerWidth)).url()} />
                </div>)},
        },
        marks: {
            link: ({children, value}) => {
              return (
                <a href={value.href} target="_blank">
                  {children}
                </a>
              )
            },
          },
      }

      const bottomComponents = {
        types: {
          image: ({value}) =>{
          return(<div style={{width:pageContent.logoWidth + 'px'}} className={`bottom-image`}>
                <img src={urlFor(value.asset).width(Math.round(window.innerWidth)).url()} />
                </div>)},
        },
        marks: {
            link: ({children, value}) => {
              return (
                <a href={value.href} target="_blank">
                  {children}
                </a>
              )
            },
          },
      }

    const returnOrganisedList = (listItems)=>{
        let latestYear;

        const returnImageWidth = (image) =>{
            // console.log(image)
            if ('imageWidth' in image){
                if(image.imageWidth == '16')return '16.5%'
                if(image.imageWidth == '25')return '25%'
                if(image.imageWidth == '33')return '33%'
                if(image.imageWidth == '50')return '50%'
                if(image.imageWidth == '66')return '66%'
                if(image.imageWidth == '82')return '82.5%'
                if(image.imageWidth == '100')return '100%'
            }else{
                return '100%'
            }
        }

        return listItems.map((item, index) =>{
            if (item._type == 'arrayImage'){
                return <div key={item._key} style={{width:returnImageWidth(item)}} className="list-item-image">
                    <img src={urlFor(item.asset).width(Math.round(window.innerWidth)).url()} />
                </div>
            }
            if (item.year != latestYear){
                latestYear = item.year;
                return <div key={item._key} className="list-item divider">
                    <div className="list-item-year-divider">{item.year}</div>
                    <div className="list-item-title">{item.title}</div>
                </div>
            } else{
                return <div key={item._key} className="list-item">
                <div className="list-item-year"></div>
                <div className="list-item-title">{item.title}</div>
            </div>
            }
        })
    }

    if(!pageContent) return <></>
    return(
        <div className="info">
           <div className="content-wrapper">
            <PortableText
                value={pageContent.text}
                components={components}
            />
                <div className="list-wrapper" ref={exhibitionsRef}>
                    <div className="list-title">Selected exhibitions</div>
                    {returnOrganisedList(pageContent.exhibitions)}
                </div>
                <div className="list-wrapper" ref={commissionsRef}>
                    <div className="list-title">Commissions</div>
                    {returnOrganisedList(pageContent.commissions)}
                    {/* {pageContent.commissions.map(item =>{
                        let latestYear;
                        if (item.year != latestYear){
                            latestYear = item.year;
                            return <div key={item._key} className="list-item">
                                <div className="list-item-year">{item.year}</div>
                                <div className="list-item-title">{item.title}</div>
                            </div>
                        } else{
                            return <div key={item._key} className="list-item">
                            <div className="list-item-year"></div>
                            <div className="list-item-title">{item.title}</div>
                        </div>
                        }
                    })} */}
                </div>
                <div className="list-wrapper" ref={lecturesRef}>
                    <div className="list-title">Teaching / Workshops / Lectures</div>
                    {returnOrganisedList(pageContent.lectures)}
                    {/* {pageContent.lectures.map(item =>{
                        return <div key={item._key} className="list-item">
                            <div className="list-item-year">{item.year}</div>
                            <div className="list-item-title">{item.title}</div>
                            </div>
                    })} */}
                </div>
                <div className="list-wrapper" ref={grantsawardsRef}>
                    <div className="list-title">Grants / Awards</div>
                    {returnOrganisedList(pageContent.grantsawards)}
                    {/* {pageContent.grantsawards.map(item =>{
                        return <div key={item._key} className="list-item">
                            <div className="list-item-year">{item.year}</div>
                            <div className="list-item-title">{item.title}</div>
                            </div>
                    })} */}
                </div>
                <div className="list-wrapper" ref={publicationsRef}>
                    <div className="list-title">Publications</div>
                    {returnOrganisedList(pageContent.publications)}
                    {/* {pageContent.publications.map(item =>{
                        return <div key={item._key} className="list-item">
                            <div className="list-item-year">{item.year}</div>
                            <div className="list-item-title">{item.title}</div>
                            </div>
                    })} */}
                </div>

                <div className="mondrian-wrapper">
                    {/* {console.log(pageContent)} */}
                <PortableText
                    value={pageContent.bottomText}
                    components={bottomComponents}
                />
                    {/* <div className="mondrian-text">
                        With the kind support of
                    </div>
                    <div className="mondrian-image">
                        <img src={process.env.PUBLIC_URL + '/assets/logo_mondriaan_fund_90_80.jpeg'}/>
                    </div> */}
                </div>
           </div>
           
            {isTabletOrMobile ?
            <>
                {navOpen == false ? 
                <div className={`bottom-nav-mobile-closed`}>
                    <div className="bottom-nav-label">
                        {activeSection == 'exhibitions' ? 'Exhibitions' :
                        activeSection == 'commissions' ? 'Commissions' :
                        activeSection == 'lectures' ? 'Teaching / Workshops / Lectures' :
                        activeSection == 'grantsawards' ? 'Grants / Awards' :
                        activeSection == 'publications' ? 'Publications' : ''
                        }
                    </div>
                    <div className="bottom-nav-toggle-button" onClick={()=>toggleNavOpen(true)}><BiChevronUp /></div>
                </div>
                :
                <div className={`bottom-nav-mobile-open`}>
                    <div className="bottom-nav-toggle-button" onClick={()=>toggleNavOpen(false)}><BiChevronDown/></div>
                    <div
                    onClick={()=>{scrollToHandler(exhibitionsRef)}}
                    className={`bottom-section-link ${activeSection == 'exhibitions' ? 'section-active' : ''}`}>
                        Exhibitions
                    </div>
                    <div
                    onClick={()=>{scrollToHandler(commissionsRef)}}
                    className={`bottom-section-link ${activeSection == 'commissions' ? 'section-active' : ''}`}>
                        Commissions
                    </div>
                    <div
                    onClick={()=>{scrollToHandler(lecturesRef)}}
                    className={`bottom-section-link ${activeSection == 'lectures' ? 'section-active' : ''}`}>
                        Teaching / Workshops / Lectures
                    </div>
                    <div
                    onClick={()=>{scrollToHandler(grantsawardsRef)}}
                    className={`bottom-section-link ${activeSection == 'grantsawards' ? 'section-active' : ''}`}>
                        Grants / Awards
                    </div>
                    <div
                    onClick={()=>{scrollToHandler(publicationsRef)}}
                    className={`bottom-section-link ${activeSection == 'publications' ? 'section-active' : ''}`}>
                        Publications
                    </div>
                </div>
                }
            </>
                :
            <div className="bottom-wrapper">
                <div
                onClick={()=>{scrollToHandler(exhibitionsRef)}}
                className={`bottom-section-link ${activeSection == 'exhibitions' ? 'section-active' : ''}`}>
                    Exhibitions
                </div>
                <div
                onClick={()=>{scrollToHandler(commissionsRef)}}
                className={`bottom-section-link ${activeSection == 'commissions' ? 'section-active' : ''}`}>
                    Commissions
                </div>
                <div
                onClick={()=>{scrollToHandler(lecturesRef)}}
                className={`bottom-section-link ${activeSection == 'lectures' ? 'section-active' : ''}`}>
                    Teaching / Workshops / Lectures
                </div>
                <div
                onClick={()=>{scrollToHandler(grantsawardsRef)}}
                className={`bottom-section-link ${activeSection == 'grantsawards' ? 'section-active' : ''}`}>
                    Grants / Awards
                </div>
                <div
                onClick={()=>{scrollToHandler(publicationsRef)}}
                className={`bottom-section-link ${activeSection == 'publications' ? 'section-active' : ''}`}>
                    Publications
                </div>
            </div>
            }

        </div>
    )
}

export default Info