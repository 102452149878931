import React, {useEffect, useState, useRef} from "react";
import './Allthetimes.styles.scss'
import $ from "jquery";

const Allthetimes = ({width})=>{

    const [currentTime, setCurrentTime] = useState(null)
    const clockWrapperRef = useRef()
    const [clockHeight, setClockHeight] = useState('100%')

$.noConflict();
$( document ).ready(function(e) {
    var t, n, r, i, s, o, u, a, f;
    r = function(e) {
        switch (e) {
            case "m":
                t = String("01,02,03,04,05,06,07,08,09,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,00").split(",");
                break;
            case "h":
                n = String("&nbsp;1,&nbsp;2,&nbsp;3,&nbsp;4,&nbsp;5,&nbsp;6,&nbsp;7,&nbsp;8,&nbsp;9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,&nbsp;0").split(",");
                break;
            default:
                u();
                console.log("24: ", o);
                break
        }
    };
    u = function() {
        o = [];
        for (var e = n.length - 1; e >= 0; e--) {
            if (t.length == 0) {
                r("m")
            }
            for (var i = t.length - 1; i >= 0; i--) {
                o.push([n[e], t[i]])
            }
        }
    };
    s = function() {
        if (o.length == 0) {
            r()
        }
        var t = Math.floor(Math.random() * o.length);
        e("#clock .hours").html(o[t][0]);
        e("#clock .mins").html(o[t][1]);
        o.splice(t, 1)
    };
    f = function() {
        e("#clock .blink").html(":");
        setTimeout(function() {
            e("#clock .blink").html("&nbsp;")
        }, 500)
    };
    r("m");
    r("h");
    r();
    s();
    i = setInterval(function() {
        s()
    }, 6e4);
    a = setInterval(function() {
        f()
    }, 1e3)
})

const returnHeight = ()=>{
    if (clockWrapperRef == undefined){return '100%'}
    if (clockWrapperRef.current == undefined){return '100%'}
    if ('current' in clockWrapperRef){
        console.log(clockWrapperRef.current)
        let width = clockWrapperRef.current.clientWidth;
        let height = width * 0.75;
        return height + 'px'
    }else{
        return '100%'
    }
}
useEffect(()=>{
    console.log(clockWrapperRef)
    if (clockWrapperRef == undefined){return}
    if (clockWrapperRef.current == undefined){return}
    let width = clockWrapperRef.current.clientWidth;
    let height = width * 0.5;
    // console.log(height)
    setClockHeight(height +'px');
    // setFontSize()

},[clockWrapperRef])


    return(
        <div ref={clockWrapperRef} style={{height:clockHeight}} className={`clock-wrapper width-${width}`}>
            <div id="clock" style={{height:clockHeight}} className="group">
                <div className="display">
                    <span className="hours" style={{lineHeight:clockHeight}}>
                        <span className="spacer">
                            &nbsp;
                        </span>6
                    </span>
                    <span className="blink">:
                    </span>
                    <span className="mins" style={{lineHeight:clockHeight}}>48
                    </span>
                </div>
            </div>
        </div>
    )
}

export default Allthetimes