import React, { useContext } from "react";
import './titleCursor.styles.scss'
import useMousePosition from "../../components/hooks/useMousePosition";
import {MouseContext} from "../../context/mouse-context";

const TitleCursor = () => {
  const { cursorType, cursorChangeHandler } = useContext(MouseContext);
  const { x, y } = useMousePosition();
  const rightMargin = window.innerWidth - 320
  // console.log(cursorType);
  if (!cursorType) return <></>
  return (
    <>
        {/* {cursorType == 'next' ?  */}
            <div
              className={`cursor ${x > rightMargin ? 'left-align':''}`}
              style={{ left: `${x}px`, top: `${y}px` }}
            >
              <span className="label">{cursorType}</span>
            </div>
            {/* :
            cursorType == 'previous' ?
            <div
            className={"cursor-previous"}
            style={{ left: `${x}px`, top: `${y}px` }}
          >
            <span className="arrow-wrapper"><BsArrowLeft/></span> PREVIOUS
          </div>
          :
          ''
        } */}

    </>
  );
};

export default TitleCursor;
