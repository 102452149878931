import React, { useEffect, useState, useContext, useRef } from "react";
import sanityClient from "../../client.js";
import { useLocation, useNavigate } from "react-router-dom";
import Zoomable from "react-instagram-zoom";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import "./project.styles.scss";
// import TitleCursor from '../../components/titleCursor/titleCursor.component'
import Menu from "../../components/menu/menu.component";
import Allthetimes from "../../components/Allthetimes/Allthetimes.component";
import { PortableText } from "@portabletext/react";
import imageUrlBuilder from "@sanity/image-url";
import getVideoId from "get-video-id";
import ReactPlayer from "react-player";
import { useMediaQuery } from "react-responsive";
import { getImageDimensions } from "@sanity/asset-utils";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";

import { getFile } from "@sanity/asset-utils";

import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

const Project = ({ setPreventIdle }) => {
  const [pageContent, setPageContent] = useState(null);
  const [navData, setNavData] = useState(null);
  const [layout, setLayout] = useState("first");
  const [pageWidth, setPageWidth] = useState(window.innerWidth);

  const projectTextRef = useRef(null);
  const [narrowProjectText, setNarrowProjectText] = useState(null);
  const mediaWrapperRef = useRef();
  const [returnedProjectRows, setReturnedProjectRows] = useState(null);

  const [projectTextHeight, setProjectTextHeight] = useState(0);

  const breakpointColumnsObj = {
    default: 6,
    1300: 4,
    850: 2,
  };

  const isNarrow = useMediaQuery({
    query: "(max-width: 850px)",
  });

  const isTablet = useMediaQuery({ minWidth: 767, maxWidth: 1100 });

  const is1000 = useMediaQuery({
    query: "(max-width: 1000px)",
  });

  useEffect(() => {
    if (isNarrow) {
      setLayout("second");
    }
  }, [isNarrow]);

  useEffect(() => {
    if (!pageContent) return;
    updateProjectRows(window.innerWidth);
    setPageWidth(window.innerWidth);
    const handler = () => {
      if (window.innerWidth != pageWidth) {
        console.log("resize");
        // window.location.reload(false)
      }
    };
    window.addEventListener("resize", handler); // Or window resize
    return () => {
      // On unmount, remove the handler
      window.removeEventListener("resize", handler); // Or window resize
    };
  }, [pageContent]);

  // useEffect(()=>{
  //     console.log(pageWidth)
  // },[pageWidth])

  const VideoElement = ({ url, width, isNarrow, pageWidth }) => {
    // console.log('element', width)
    const [videoWidth, setVideoWidth] = useState(width);
    const videoRef = useRef(null);
    const [videoHeight, setVideoHeight] = useState("auto");

    const handleVideoHeight = () => {
      // // console.log(pageWidth)
      // console.log(videoWidth)
      let margin;
      switch (videoWidth) {
        case "25":
          margin = 210;
          break;
        case "33":
          margin = 166;
          break;
        case "55":
          margin = 110;
          break;
        case "66":
          margin = 110;
          break;
        case "82":
          margin = 66;
          break;
        case "100":
          margin = 44;
          break;
        default:
          margin = 110;
      }
      if (isNarrow) {
        margin = 44;
      }
      let aspectRatio =
        videoRef.current.children[0].children[0].children[0].height /
        videoRef.current.children[0].children[0].children[0].width;
      let videoPixelWidth = (pageWidth - margin) / (100 / videoWidth); //TODO: somehow making it too narrow, how to get the accurate dimensions
      let videoHeight = Math.round(videoPixelWidth * aspectRatio);
      // console.log(videoHeight)
      setVideoHeight(videoHeight);
    };

    // useEffect(()=>{
    //     if(videoRef == 'undefined')return
    //     if(!'current' in videoRef)return
    //     if(!'children' in videoRef.current)return
    //     console.log('ref', videoRef.current.children)
    //     // if(videoHeight == 'auto') return
    //     handleVideoHeight()
    //     console.log('update', pageWidth)
    // },[pageWidth, videoRef])

    // useEffect(()=>{
    //     // console.log(width)
    //     setVideoWidth(width)
    // },[pageWidth,width])

    return (
      <div ref={videoRef}>
        <ReactPlayer
          // key={pageWidth}
          //onPlaying => call to set global state to not activate screensaver
          // onPause => set state to null again
          playsinline={true}
          onPlay={() => setPreventIdle(true)}
          onPause={() => setPreventIdle(false)}
          onEnded={() => setPreventIdle(false)}
          width="100%"
          controls={true}
          onReady={(e) => handleVideoHeight(e)}
          height={videoHeight}
          url={url}
        />
      </div>
    );
  };

  // scroll detection

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    if (position > window.innerHeight * 1.5) {
      setScrollPosition(position);
    } else {
      setScrollPosition(null);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  let location = useLocation();
  const currentSlug = location.pathname.replace("/", "");
  // const currentSlug = location.pathname
  //   console.log(currentSlug)

  useEffect(() => {
    let fetchContentQuery = `*[slug.current == "${currentSlug}"]{...}[0]`;
    sanityClient
      .fetch(fetchContentQuery)
      .then((data) => {
        setPageContent(data);
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    if (currentSlug == null) return;
    let fetchContentQuery = `*[_type == "project"]{...} | order(orderRank)`;
    sanityClient
      .fetch(fetchContentQuery)
      .then((data) => {
        let navData = {};
        for (let i = 0; data.length > i; i++) {
          // console.log(data[i])
          // console.log(data[i].slug)
          if (data[i].slug.current == currentSlug) {
            // check if it's the first or last in the list

            if (i > 0) {
              navData.prevUrl = data[i - 1].slug.current;
            }
            if (i + 1 < data.length) {
              navData.nextUrl = data[i + 1].slug.current;
            }
          }
        }
        setNavData(navData);
        // console.log(data);
        // setPageContent(data)
      })
      .catch(console.error);
  }, [pageContent, currentSlug]);

  const returnFullsizeDimension = (asset) => {
    const string = asset.split("-");
    const size = string[2].split("x");

    const getDimension = (size) => {
      let ratio = size[0] / size[1];
      // console.log(ratio);
      if (ratio < 1.5) {
        return "portrait";
      } else {
        return "landscape";
      }
    };

    return getDimension(size);
  };

  const returnVideoHeight = (width, aspectRatio) => {
    if (isNarrow) {
      let pixelWidth = window.innerWidth * (width / 100);
      return pixelWidth * 0.53;
    } else {
      let pixelWidth = (window.innerWidth - 44) * (width / 100);
      return pixelWidth * 0.53;
    }
  };

  const returnVideoWidth = (width) => {
    if (isNarrow) {
      let pixelWidth = (window.innerWidth - 44) * (width / 100);
      return pixelWidth;
    } else {
      let pixelWidth = (window.innerWidth - 110) * (width / 100);
      return pixelWidth;
    }
  };

  const scrollToHandler = () => {
    console.log("scroll");
    window.scrollTo(0, 0);
  };

  const portableTextComponents = {
    marks: {
      link: ({ children, value }) => {
        return (
          <a href={value.href} target="_blank">
            {children}
          </a>
        );
      },
    },
    // types:{
    //     file: ({value}) =>{
    //         console.log(value)
    //         if ('audio' in value){
    //             let file = getFile(value.audio.asset, sanityClient.config())
    //             return <div className="audio-file-wrapper">
    //                   <AudioPlayer
    //                       className="audio-file"
    //                       src={file.asset.url}
    //                     //   onPlay={e => onPlayAudio(file.asset.url)}
    //                       // other props here
    //                   />
    //             </div>
    //         }else{
    //             return <></>
    //         }

    //       },
    // }
  };

  const updateProjectRows = (width) => {
    // console.log('updating')

    let projectRows = returnProjectRows(width);
    setReturnedProjectRows(projectRows);
  };

  const returnProjectRows = (width) => {
    // console.log(width)
    const returnCurrentElement = (element) => {
      let clear = false;
      if ("clear" in element) {
        clear = element.clear;
      }
      if (element.type == "Image") {
        // if (element.image.caption == 'Bianco'){
        //     console.log(element);
        // }

        if ("size" in element) {
          if (isNarrow) {
            return (
              <div className={`image-wrapper width-${element.size}`}>
                {/* {isTablet ? 
                            <LazyLoadImage
                            effect="opacity"
                            src={urlFor(element.image.asset).width(Math.round(window.innerWidth*2))}
                            placeholderSrc={urlFor(element.image.asset).quality(50)}
                            />
                            : */}
                {/* // <Zoomable> */}
                <LazyLoadImage
                  effect="opacity"
                  src={urlFor(element.image.asset).width(
                    Math.round(window.innerWidth * 2)
                  )}
                  placeholderSrc={urlFor(element.image.asset).quality(50)}
                />
                {/* // </Zoomable> */}
                {/* } */}
                {"caption" in element.image ? (
                  <div
                    className={`image-caption caption-align-${
                      "captionAlign" in element ? element.captionAlign : "left"
                    }`}
                  >
                    {element.image.caption}
                  </div>
                ) : (
                  ""
                )}
                {"captionBlock" in element.image ? (
                  <div
                    className={`image-caption caption-align-${
                      "captionAlign" in element ? element.captionAlign : "left"
                    }`}
                  >
                    <PortableText
                      value={element.image.captionBlock}
                      components={portableTextComponents}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            );
          } else {
            let doublePixelWidth =
              Math.round(window.innerWidth / (100 / element.size)) * 2;
            return (
              <div
                className={`image-wrapper ${clear ? "div-clear" : ""} width-${
                  element.size
                } ${"pos" in element ? `pos-${element.pos}` : ""}`}
              >
                <LazyLoadImage
                  effect="opacity"
                  src={urlFor(element.image.asset).width(doublePixelWidth)}
                  placeholderSrc={urlFor(element.image.asset).quality(50)}
                />
                {/* <img src={urlFor(element.image.asset).width(Math.round(window.innerWidth * 2))}/> */}

                {"caption" in element.image ? (
                  <div
                    className={`image-caption caption-align-${
                      "captionAlign" in element ? element.captionAlign : "left"
                    }`}
                  >
                    {element.image.caption}
                  </div>
                ) : (
                  ""
                )}
                {"captionBlock" in element.image ? (
                  <div
                    className={`image-caption caption-align-${
                      "captionAlign" in element ? element.captionAlign : "left"
                    }`}
                  >
                    <PortableText
                      value={element.image.captionBlock}
                      components={portableTextComponents}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            );
          }
        } else {
          let doublePixelWidth = Math.round(window.innerWidth / (100 / 33)) * 2;
          // firstImageRowWidth = firstImageRowWidth + 33;
          return (
            <div
              className={`image-wrapper ${clear ? "div-clear" : ""} width-33`}
            >
              {/* {isTablet ? 
                            <LazyLoadImage
                            effect="opacity"
                            src={urlFor(element.image.asset).width(doublePixelWidth)}
                            placeholderSrc={urlFor(element.image.asset).quality(50)}
                            />
                            :
                            <Zoomable> */}
              <LazyLoadImage
                effect="opacity"
                src={urlFor(element.image.asset).width(doublePixelWidth)}
                placeholderSrc={urlFor(element.image.asset).quality(50)}
              />
              {/* </Zoomable>
                            } */}
              {"caption" in element.image ? (
                <div
                  className={`image-caption caption-align-${
                    "captionAlign" in element ? element.captionAlign : "left"
                  }`}
                >
                  {element.image.caption}
                </div>
              ) : (
                ""
              )}
              {"captionBlock" in element.image ? (
                <div
                  className={`image-caption caption-align-${
                    "captionAlign" in element ? element.captionAlign : "left"
                  }`}
                >
                  <PortableText
                    value={element.image.captionBlock}
                    components={portableTextComponents}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          );
        }
      } else if (element.type == "Text") {
        let align = "align-left";
        if ("textAlign" in element) {
          // console.log(element.textAlign)
          align = "align-" + element.textAlign;
        }

        let pos = false;
        if ("pos" in element) {
          pos = element.pos;
        }

        let size = "33";
        if ("size" in element) {
          size = element.size;
        }

        if (isNarrow) {
          // console.log(element)
          if ("isProjectText" in element && !narrowProjectText) {
            if (element.isProjectText == true) {
              setNarrowProjectText(element.text);
              return <></>; //TODO: check that this works
            }
          }
          // else if ('isProjectText' in element && narrowProjectText){
          // }
          else {
            if (element.isProjectText == true) {
              // setNarrowProjectText(element.text)
              return <></>; //
            } else {
              return (
                <div className={`text-wrapper ${align}`}>
                  <PortableText
                    value={element.text}
                    components={portableTextComponents}
                  />
                </div>
              );
            }
          }
        }
        if (pos) {
          return (
            <div className="text-pos-wrapper">
              <div
                className={`text-wrapper pos-${pos} ${align} ${
                  clear ? "div-clear" : ""
                } width-${size}`}
              >
                <PortableText
                  value={element.text}
                  components={portableTextComponents}
                />
              </div>
            </div>
          );
        } else {
          return (
            <div
              className={`text-wrapper ${align} ${
                clear ? "div-clear" : ""
              } width-${size}`}
            >
              <PortableText
                value={element.text}
                components={portableTextComponents}
              />
            </div>
          );
        }
      } else if (element.type == "Video") {
        let size = 66;

        if ("size" in element) {
          size = element.size;
        }
        // console.log('return', size)
        if (isNarrow) {
          size = 100;
        }
        return (
          <div
            className={`video-wrapper ${
              clear ? "div-clear" : ""
            } width-${size} ${"pos" in element ? `pos-${element.pos}` : ""}`}
          >
            <VideoElement
              key={width + size}
              pageWidth={width}
              url={element.video}
              width={size}
              isNarrow={isNarrow}
            />
          </div>
        );
      } else if (element.type == "Audio") {
        console.log(element);
        let file = getFile(element.audio.asset, sanityClient.config());

        let size = 33;

        if ("size" in element) {
          size = element.size;
        }
        // console.log('return', size)
        if (isNarrow) {
          size = 100;
        }

        return (
          <div
            className={`audio-file-wrapper ${
              clear ? "div-clear" : ""
            } width-${size} ${"pos" in element ? `pos-${element.pos}` : ""}`}
          >
            {/* <audio className="audio-file" src={file.asset.url} ></audio> */}

            {/* <audio src={file.asset.url} controls /> */}
            <audio
              className="audio-file"
              controlsList="nodownload noplaybackrate"
              controls
            >
              <source src={file.asset.url} type="audio/mpeg" />
            </audio>

            {/* <AudioPlayer
                            className="audio-file"
                            src={file.asset.url}
                            showJumpControls={false}
                            showDownloadProgress={false}
                            autoPlayAfterSrcChange={false}
                        //   onPlay={e => onPlayAudio(file.asset.url)}
                            // other props here
                        /> */}
          </div>
        );
      } else if (element.type == "Empty space") {
        if ("size" in element) {
          return (
            <div
              className={`empty-wrapper ${clear ? "div-clear" : ""} width-${
                element.size
              } ${"pos" in element ? `pos-${element.pos}` : ""}`}
            ></div>
          );
        } else {
          return (
            <div
              className={`empty-wrapper ${clear ? "div-clear" : ""} width-33`}
            ></div>
          ); //TODO: check that this works
        }
      } else if (element.type == "Line break") {
        return <div className={"line-break"}></div>;
      } else if (element.type == 'Special element - "All the Times"') {
        return (
          <>
            <Allthetimes width={element.size} />
          </>
        );
      }
    };

    let completeArray = [];

    for (let i = 0; pageContent.elements.length > i; i++) {
      const currentElement = returnCurrentElement(pageContent.elements[i]);
      completeArray.push(currentElement);
    }

    // console.log(completeArray)
    return completeArray.map((element) => {
      // console.log(element)
      return element;
    });
  };

  if (!pageContent || !navData) return <></>;
  return (
    <div className={`project ${layout}`}>
      <div key={pageWidth} ref={mediaWrapperRef} className="media-wrapper">
        {narrowProjectText ? (
          <div className="text-wrapper">
            <PortableText
              value={narrowProjectText}
              components={portableTextComponents}
            />
          </div>
        ) : (
          ""
        )}
        {returnedProjectRows}
        {/* {pageContent.elements.length > 5 ?
                        <div className="project-scroll-button" onClick={()=> scrollToHandler()}><div>Scroll up</div></div>
                    :''} */}
      </div>
      <div className="bottom-wrapper">
        <div className={`bottom-nav-prev`}>
          {"prevUrl" in navData ? (
            <a href={`/${navData.prevUrl}`}>Previous</a>
          ) : (
            ""
          )}
        </div>
        <div className={`bottom-nav-middle`}>
          <div
            className={scrollPosition ? "button-fade-in" : ""}
            onClick={() => scrollToHandler()}
          >
            Scroll up
          </div>

          {/* {!isNarrow ? '' : pageContent.title} */}
        </div>

        <div className={`bottom-nav-next`}>
          {"nextUrl" in navData ? <a href={`/${navData.nextUrl}`}>Next</a> : ""}
        </div>
      </div>
    </div>
  );
};

export default Project;
