import React, {useEffect, useState} from "react";
import './Screensaver.styles.scss'
import { useDvdScreensaver } from 'react-dvd-screensaver'

const Screensaver = ({isTablet, isNarrow})=>{

const [emojiCounter, setEmojiCounter] = useState(0)
const [firstEmoji, toggleFirstEmoji] = useState(true)

const { containerRef, elementRef, hovered, impactCount } = useDvdScreensaver({
freezeOnHover: true,
speed: isNarrow ? 1 : isTablet ? 1.5 : 2
});

  useEffect(()=>{
    console.log(emojiCounter)
    if(firstEmoji){
      toggleFirstEmoji(false)
      return
    }
    if (emojiCounter == 24){
      setEmojiCounter(0)
    } else{
      setEmojiCounter(emojiCounter + 1)
    }
  },[impactCount])

  // useEffect(()=>{
  //   if(!firstEmoji) return
  //   toggleFirstEmoji(true)
  // },[])

    return(
        <div className="screensaver-container">
        <div ref={containerRef}
        className="hooks-container">
          <div ref={elementRef} 
          className="hooks-element">
            <img className={emojiCounter == 0 ? 'show-emoji' :''} src={process.env.PUBLIC_URL + '/assets/0.png'}/>
            <img className={emojiCounter == 1 ? 'show-emoji' :''} src={process.env.PUBLIC_URL + '/assets/1.png'}/>
            <img className={emojiCounter == 2 ? 'show-emoji' :''} src={process.env.PUBLIC_URL + '/assets/2.png'}/>
            <img className={emojiCounter == 3 ? 'show-emoji' :''} src={process.env.PUBLIC_URL + '/assets/3.png'}/>
            <img className={emojiCounter == 4 ? 'show-emoji' :''} src={process.env.PUBLIC_URL + '/assets/4.png'}/>
            <img className={emojiCounter == 5 ? 'show-emoji' :''} src={process.env.PUBLIC_URL + '/assets/5.png'}/>
            <img className={emojiCounter == 6 ? 'show-emoji' :''} src={process.env.PUBLIC_URL + '/assets/6.png'}/>
            <img className={emojiCounter == 7 ? 'show-emoji' :''} src={process.env.PUBLIC_URL + '/assets/7.png'}/>
            <img className={emojiCounter == 8 ? 'show-emoji' :''} src={process.env.PUBLIC_URL + '/assets/8.png'}/>

            <img className={emojiCounter == 9 ? 'show-emoji' :''} src={process.env.PUBLIC_URL + '/assets/9.png'}/>
            <img className={emojiCounter == 10 ? 'show-emoji' :''} src={process.env.PUBLIC_URL + '/assets/10.png'}/>
            <img className={emojiCounter == 11 ? 'show-emoji' :''} src={process.env.PUBLIC_URL + '/assets/11.png'}/>
            <img className={emojiCounter == 12 ? 'show-emoji' :''} src={process.env.PUBLIC_URL + '/assets/12.png'}/>
            <img className={emojiCounter == 13 ? 'show-emoji' :''} src={process.env.PUBLIC_URL + '/assets/13.png'}/>
            <img className={emojiCounter == 14 ? 'show-emoji' :''} src={process.env.PUBLIC_URL + '/assets/14.png'}/>
            <img className={emojiCounter == 15 ? 'show-emoji' :''} src={process.env.PUBLIC_URL + '/assets/15.png'}/>
            <img className={emojiCounter == 16 ? 'show-emoji' :''} src={process.env.PUBLIC_URL + '/assets/16.png'}/>
            <img className={emojiCounter == 17 ? 'show-emoji' :''} src={process.env.PUBLIC_URL + '/assets/17.png'}/>

            <img className={emojiCounter == 18 ? 'show-emoji' :''} src={process.env.PUBLIC_URL + '/assets/18.png'}/>
            <img className={emojiCounter == 19 ? 'show-emoji' :''} src={process.env.PUBLIC_URL + '/assets/19.png'}/>
            <img className={emojiCounter == 20 ? 'show-emoji' :''} src={process.env.PUBLIC_URL + '/assets/20.png'}/>
            <img className={emojiCounter == 21 ? 'show-emoji' :''} src={process.env.PUBLIC_URL + '/assets/21.png'}/>
            <img className={emojiCounter == 22 ? 'show-emoji' :''} src={process.env.PUBLIC_URL + '/assets/22.png'}/>
            <img className={emojiCounter == 23 ? 'show-emoji' :''} src={process.env.PUBLIC_URL + '/assets/23.png'}/>
            <img className={emojiCounter == 24 ? 'show-emoji' :''} src={process.env.PUBLIC_URL + '/assets/24.png'}/>

          </div>
        </div>
      </div>
    )
}

export default Screensaver